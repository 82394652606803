import { type YumRoute } from '@mr-yum/frontend-core/dist/services/routes'
import pick from 'lodash/pick'
import pathToRegexp from 'path-to-regexp'
import qs from 'query-string'
import { ParsedUrlQuery } from 'querystring'
import { mapStaffRoleToValidRole } from 'utils/mapStaffRoleToValidRole'

import { FEATURE_PERMISSIONS } from '../common/featurePermissions'
import { ValidRole } from './auth'
import { OrderingTypeSlug } from './orderingTypeUtils'

export interface ManageRoute<T extends {} | undefined = undefined>
  extends YumRoute<T> {
  roles: ValidRole[]
}

export interface VenueSlug {
  venueId: string
}

interface VenueOrderSlug {
  venueId: string
  orderId: string
}

interface VenueTabSlug<Tab extends string> {
  venueId: string
  tab: Tab
}

type VenueMenuSlug<Type extends string> =
  // @cleanup: RoleRouteLink is collapsing an overlapping union, leaving
  // this workaround until we've decided what we're doing with
  // the routing
  // | {
  //     venueId: string
  //   }
  {
    venueId: string
    type?: Type
    id?: string
  }

// type VenueMenuListSlug<Type extends string> = {
//   venueId: string
//   type: Type
// }

type VenueMenuItemSlug = {
  itemId: string
  venueId: string
  type?: 'category' | 'item'
  id?: string
}

interface VenueUpsellGroupSlug {
  venueId: string
  id: string
  edit?: boolean
}

interface MrYumSlug<Tab extends string> {
  tab: Tab
}

interface OrganizationSlug {
  organizationId: string
}

interface OrganizationStaffSlug {
  organizationId: string
  staffId: string
}

interface OrganizationConnectSlug {
  organizationId: string
  id: string
}

interface EverythingSlug {
  view: 'venue-weeks'
}

export interface VenueOrderingTypeSlug extends VenueSlug {
  orderingTypeSlug: OrderingTypeSlug
}

export interface VenueBillingInvoiceSlug extends VenueSlug {
  invoiceId: string
}

export interface SharedPaymentId extends VenueSlug {
  sharedPaymentId: string
}

export interface ModifierGroupSlug extends VenueSlug {
  groupId?: string
  fromSearch?: string
  fromPage?: string
  fromMenuItem?: string
}

export interface ModifierGroupsSlug extends VenueSlug {
  search?: string
  page?: string
}

export interface PriceAdjustmentSlug extends VenueSlug {
  adjustmentId?: string
}

export interface MatchRoute<T> {
  matchRoute(path: string, pathData: ParsedUrlQuery): T | undefined
}

// @deprecated: use getQueryV2 instead
const getQuery = <T extends {}>(pathData: T, keys: (keyof T)[]) => {
  const query = pick(pathData, keys)
  const emptyQuery = Object.values(query).every((el) => el === undefined)

  if (!emptyQuery) {
    return '?' + qs.stringify(query)
  }

  return ''
}

// uses native URLSearchParams and preserves order of passed in keys
const getQueryV2 = <T extends {}>(pathData: T, keys: (keyof T)[]) => {
  if (keys.every((key) => pathData[key] === undefined)) {
    return ''
  }

  const query = pick(pathData, keys)

  const params = new URLSearchParams()

  // respect the order of keys
  keys.forEach((key) => {
    if (query[key] !== undefined) {
      params.append(String(key), String(query[key]))
    }
  })

  return `?${params.toString()}`
}

const validModifierGroupRoles = FEATURE_PERMISSIONS.modifierGroup.readRoles.map(
  mapStaffRoleToValidRole,
)

const validRewardsV2Roles = [
  ...new Set([
    ...FEATURE_PERMISSIONS.reward.writeRoles,
    ...FEATURE_PERMISSIONS.rewardActivation.writeRoles,
  ]),
].map(mapStaffRoleToValidRole)

const validSalesReportRoles = FEATURE_PERMISSIONS.salesReport.readRoles.map(
  mapStaffRoleToValidRole,
)

const validStripeConnectRoles =
  FEATURE_PERMISSIONS.stripeConnect.writeRoles.map(mapStaffRoleToValidRole)

const validRolesForOrganizationStaffPage =
  FEATURE_PERMISSIONS.organizationStaff.readRoles.map(mapStaffRoleToValidRole)

const validBulkEditMenuItemsRoles =
  FEATURE_PERMISSIONS.menuItems.writeRoles.map(mapStaffRoleToValidRole)

const validOrderHistoryRoles = FEATURE_PERMISSIONS.orderHistory.writeRoles.map(
  mapStaffRoleToValidRole,
)

const validPriceAdjustmentWriteRoles =
  FEATURE_PERMISSIONS.priceAdjustments.writeRoles.map(mapStaffRoleToValidRole)
const validPriceAdjustmentReadRoles =
  FEATURE_PERMISSIONS.priceAdjustments.readRoles.map(mapStaffRoleToValidRole)

export const routes = {
  home: {
    label: 'Home',
    href: () => '/',
  } as ManageRoute,
  venues: {
    label: 'Venues',
    href: () => '/venues',
    roles: FEATURE_PERMISSIONS.venues.readRoles.map(mapStaffRoleToValidRole),
  } as ManageRoute,
  organizations: {
    label: 'Organizations',
    href: () => '/organizations',
    roles: FEATURE_PERMISSIONS.organizations.readRoles.map(
      mapStaffRoleToValidRole,
    ),
  } as ManageRoute,
  landingPages: {
    label: 'Landing Pages',
    href: () => '/landing-pages',
    roles: FEATURE_PERMISSIONS.landingPages.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
  } as ManageRoute,
  mryumAdminTab: {
    label: 'me&u Admin',
    href: () => '/mryum-admin/[tab]',
    roles: FEATURE_PERMISSIONS.meanduAdminPage.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) => pathToRegexp.compile('/mryum-admin/:tab')(pathData),
  } as ManageRoute<
    MrYumSlug<
      'accountmanagers' | 'salesagents' | 'posreferrers' | 'venuecategories'
    >
  >,
  organizationInfo: {
    label: 'Organization Info',
    href: () => '/organizations/[organizationId]/info',
    roles: FEATURE_PERMISSIONS.organizationInfo.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/info')(pathData),
  } as ManageRoute<OrganizationSlug>,
  organizationVenues: {
    label: 'Organization Venues',
    href: () => '/organizations/[organizationId]/venues',
    roles: FEATURE_PERMISSIONS.organizationVenues.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/venues')(pathData),
  } as ManageRoute<OrganizationSlug>,
  organizationStaff: {
    label: 'Staff',
    href: () => '/organizations/[organizationId]/staff',
    roles: validRolesForOrganizationStaffPage,
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/staff')(pathData),
  } as ManageRoute<OrganizationSlug>,
  organizationStaffCreate: {
    label: 'Invite staff',
    href: () => '/organizations/[organizationId]/staff/create',
    roles: validRolesForOrganizationStaffPage,
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/staff/create')(
        pathData,
      ),
  } as ManageRoute<OrganizationSlug>,
  organizationStaffEdit: {
    label: 'Edit',
    href: () => '/organizations/[organizationId]/staff/[staffId]',
    roles: validRolesForOrganizationStaffPage,
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/staff/:staffId')(
        pathData,
      ),
  } as ManageRoute<OrganizationStaffSlug>,
  reward: {
    label: 'Reward',
    href: () => '/organizations/[organizationId]/reward',
    roles: FEATURE_PERMISSIONS.organizationReward.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/reward')(pathData),
  } as ManageRoute<OrganizationSlug>,
  rewardMemberships: {
    label: 'Reward Memberships',
    href: () => '/organizations/[organizationId]/reward/memberships',
    roles: FEATURE_PERMISSIONS.organizationRewardMemberships.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/reward/memberships')(
        pathData,
      ),
  } as ManageRoute<OrganizationSlug>,
  crm: {
    label: 'CRM',
    href: () => '/organizations/[organizationId]/crm',
    roles: FEATURE_PERMISSIONS.organizationCRM.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/crm')(pathData),
  } as ManageRoute<OrganizationSlug>,
  stripeConnect: {
    label: 'Stripe Connect',
    href: () => '/organizations/[organizationId]/connect',
    roles: FEATURE_PERMISSIONS.organizationStripeConnectPage.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/connect')(pathData),
  } as ManageRoute<OrganizationSlug>,
  yumpingo: {
    label: 'Yumpingo',
    href: () => '/organizations/[organizationId]/yumpingo',
    roles: FEATURE_PERMISSIONS.organizationYumpingo.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/yumpingo')(pathData),
  } as ManageRoute<OrganizationSlug>,
  stripeConnectBankAccount: {
    label: 'Stripe Connect Bank Account',
    href: () => '/organizations/[organizationId]/connect/[id]/bank-account',
    roles: validStripeConnectRoles,
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/organizations/:organizationId/connect/:id/bank-account',
      )(pathData),
  } as ManageRoute<OrganizationConnectSlug>,
  stripeConnectReview: {
    label: 'Stripe Connect Review',
    href: () => '/organizations/[organizationId]/connect/[id]/review',
    roles: validStripeConnectRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/connect/:id/review')(
        pathData,
      ),
  } as ManageRoute<OrganizationConnectSlug>,
  stripeConnectMove: {
    label: 'Stripe Connect Move',
    href: () => '/organizations/[organizationId]/connect/[id]/move',
    roles: FEATURE_PERMISSIONS.stripeConnectedAccount.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/connect/:id/move')(
        pathData,
      ),
  } as ManageRoute<OrganizationConnectSlug>,
  stripeConnectNew: {
    label: 'New Stripe Connected Account',
    href: () => '/organizations/[organizationId]/connect/new',
    roles: validStripeConnectRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/organizations/:organizationId/connect/new')(
        pathData,
      ),
  } as ManageRoute<OrganizationSlug>,
  manageMenu: {
    label: 'Menu Items',
    href: (pathData: VenueMenuSlug<'category' | 'item' | 'modifier'>) =>
      'type' in pathData
        ? `/venues/[venueId]/menu/${pathData.type}/[id]`
        : '/venues/[venueId]/menu',
    toPath: (pathData) =>
      'type' in pathData
        ? // when using router.back() it will call toPath with a blank id. ' ' seems to work as fallback
          pathToRegexp.compile(`/venues/:venueId/menu/:type/:id`)({
            ...pathData,
            id: pathData.id || ' ',
          })
        : pathToRegexp.compile('/venues/:venueId/menu')(pathData),
    roles: FEATURE_PERMISSIONS.menuItems.readRoles.map(mapStaffRoleToValidRole),
    matchRoute(path, pathData) {
      // Type case
      const types = ['category', 'item', 'modifier'] as const
      for (const type of types) {
        const params = { type, ...pathData }
        if (this.toPath?.(params as any) === path) {
          return params as VenueMenuSlug<'category' | 'item' | 'modifier'>
        }
      }

      // Root menu case
      if (this.toPath?.(pathData as any) === path) {
        return pathData as VenueMenuSlug<'category' | 'item' | 'modifier'>
      }

      return undefined
    },
  } as ManageRoute<VenueMenuSlug<'category' | 'item' | 'modifier'>> &
    MatchRoute<VenueMenuSlug<'category' | 'item' | 'modifier'>>,
  manageMenuItem: {
    label: 'Manage Menu Item',
    href: (pathData: VenueMenuItemSlug) =>
      'type' in pathData
        ? `/venues/[venueId]/menu/${pathData.type}/[id]?${qs.stringify({
            venueId: pathData.venueId,
            id: pathData.id,
            itemId: pathData.itemId,
          })}`
        : `/venues/[venueId]/menu?${qs.stringify({
            venueId: pathData.venueId,
            itemId: pathData.itemId,
          })}`,
    // toPath will be shown as the location and will always link to the item
    toPath: ({ id, type, venueId, itemId }) =>
      type
        ? pathToRegexp.compile(
            '/venues/:venueId/menu/:type/:id/?itemId=:itemId',
          )({
            id,
            type,
            venueId,
            itemId,
          })
        : pathToRegexp.compile('/venues/:venueId/menu/item/:id')({
            venueId,
            id: itemId,
          }),
  } as ManageRoute<VenueMenuItemSlug>,
  modifiers: {
    label: 'Modifiers',
    href: () => '/venues/[venueId]/modifiers',
    roles: validModifierGroupRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/modifiers')(pathData),
  } as ManageRoute<VenueSlug>,
  modifierGroups: {
    label: 'Modifier Groups',
    href: () => '/venues/[venueId]/modifiers/groups',
    roles: validModifierGroupRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/modifiers/groups')(pathData) +
      getQueryV2(pathData, ['search', 'page']),
  } as ManageRoute<ModifierGroupsSlug>,
  modifierOptions: {
    label: 'Modifier Options',
    href: () => '/venues/[venueId]/modifiers/options',
    roles: validModifierGroupRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/modifiers/options')(pathData),
  } as ManageRoute<VenueSlug>,
  modifierGroup: {
    label: 'Modifier Group',
    href: () => '/venues/[venueId]/modifiers/groups/[groupId]',
    roles: validModifierGroupRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/modifiers/groups/:groupId')(
        pathData,
      ) + getQueryV2(pathData, ['fromSearch', 'fromPage', 'fromMenuItem']),
  } as ManageRoute<ModifierGroupSlug>,
  modifierGroupOptions: {
    label: 'Modifier Group Modifier Options',
    href: () => '/venues/[venueId]/modifiers/groups/[groupId]/options',
    roles: validModifierGroupRoles,
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/venues/:venueId/modifiers/groups/:groupId/options',
      )(pathData) +
      getQueryV2(pathData, ['fromSearch', 'fromPage', 'fromMenuItem']),
  } as ManageRoute<ModifierGroupSlug>,
  modifierGroupLinkedMenuItems: {
    label: 'Modifier Group Linked Menu Items',
    href: () => '/venues/[venueId]/modifiers/groups/[groupId]/linked-items',
    roles: validModifierGroupRoles,
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/venues/:venueId/modifiers/groups/:groupId/linked-items',
      )(pathData) +
      getQueryV2(pathData, ['fromSearch', 'fromPage', 'fromMenuItem']),
  } as ManageRoute<ModifierGroupSlug>,
  upsells: {
    label: 'Upsells',
    href: () => '/venues/[venueId]/upsells',
    roles: FEATURE_PERMISSIONS.upsells.writeRoles.map(mapStaffRoleToValidRole),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/upsells')(pathData),
  } as ManageRoute<VenueSlug>,
  upsellGroups: {
    label: 'Upsell Groups',
    href: (pathData) =>
      '/venues/[venueId]/upsells/[id]' + getQuery(pathData, ['edit']),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/upsells/:id')(pathData) +
      getQuery(pathData, ['edit']),
  } as ManageRoute<VenueUpsellGroupSlug>,
  priceOverrideSpecials: {
    label: 'Price Override Specials',
    href: () => '/venues/[venueId]/price-override-specials',
    roles: validPriceAdjustmentReadRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/price-override-specials')(
        pathData,
      ),
  } as ManageRoute<VenueSlug>,
  priceAdjustments: {
    label: 'Price Adjustments',
    href: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/price-adjustments')(pathData),
    roles: validPriceAdjustmentReadRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/price-adjustments')(pathData),
  } as ManageRoute<VenueSlug>,
  priceAdjustmentCreate: {
    label: 'Price Adjustments',
    href: () => '/venues/[venueId]/price-adjustments/create',
    roles: validPriceAdjustmentWriteRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/price-adjustments/create')(
        pathData,
      ),
  } as ManageRoute<VenueSlug>,
  priceAdjustmentEdit: {
    label: 'Price Adjustment Details',
    href: () => '/venues/[venueId]/price-adjustments/[adjustmentId]',
    roles: validPriceAdjustmentWriteRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/price-adjustments/:adjustmentId')(
        pathData,
      ),
  } as ManageRoute<PriceAdjustmentSlug>,
  priceAdjustmentEditLinkedMenuItems: {
    label: 'Price Adjustment Linked Menu Items',
    href: () =>
      '/venues/[venueId]/price-adjustments/[adjustmentId]/linked-menu-items',
    roles: validPriceAdjustmentWriteRoles,
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/venues/:venueId/price-adjustments/:adjustmentId/linked-menu-items',
      )(pathData),
  } as ManageRoute<PriceAdjustmentSlug>,
  dailySpecials: {
    label: 'Daily Specials',
    href: () => '/venues/[venueId]/daily-specials',
    roles: FEATURE_PERMISSIONS.dailySpecials.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/daily-specials')(pathData),
  } as ManageRoute<VenueSlug>,
  info: {
    label: 'Venue Info',
    href: () => '/venues/[venueId]/info',
    roles: FEATURE_PERMISSIONS.venueInfoPage.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/info')(pathData),
  } as ManageRoute<VenueSlug>,
  homepageBranding: {
    label: 'Home Page & Branding',
    href: () => '/venues/[venueId]/homepage-branding',
    roles: FEATURE_PERMISSIONS.homeAndBranding.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/homepage-branding')(pathData),
  } as ManageRoute<VenueSlug>,
  openingHours: {
    label: 'Opening Hours',
    href: () => '/venues/[venueId]/opening-hours',
    roles: FEATURE_PERMISSIONS.openingHours.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/opening-hours')(pathData),
  } as ManageRoute<VenueSlug>,
  integrationSetup: {
    label: 'Integration Setup',
    href: () => '/venues/[venueId]/integration-setup',
    roles: FEATURE_PERMISSIONS.integrationSetup.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/integration-setup')(pathData),
  } as ManageRoute<VenueSlug>,
  tableGroups: {
    label: 'Table Groups',
    href: () => '/venues/[venueId]/table-groups',
    roles: FEATURE_PERMISSIONS.tableGroups.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/table-groups')(pathData),
  } as ManageRoute<VenueSlug>,
  venueQrCodes: {
    label: 'QR Codes',
    href: () => '/venues/[venueId]/qr-codes',
    roles: FEATURE_PERMISSIONS.qrCodes.readRoles.map(mapStaffRoleToValidRole),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/qr-codes')(pathData),
  } as ManageRoute<VenueSlug>,
  discountSurcharge: {
    label: 'Discounts/Surcharges',
    href: () => '/venues/[venueId]/discount-surcharge',
    roles: FEATURE_PERMISSIONS.discountsAndSurcharges.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/discount-surcharge')(pathData),
  } as ManageRoute<VenueSlug>,
  venueRewardSetup: {
    label: 'Reward Setup',
    href: () => '/venues/[venueId]/reward',
    roles: FEATURE_PERMISSIONS.reward.writeRoles.map(mapStaffRoleToValidRole),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/reward')(pathData),
  } as ManageRoute<VenueSlug>,
  rewardsV2: {
    label: 'Rewards',
    href: () => '/venues/[venueId]/rewards',
    roles: validRewardsV2Roles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/rewards')(pathData),
  } as ManageRoute<VenueSlug>,
  mrYumAttributes: {
    label: 'me&u Attributes',
    href: () => '/venues/[venueId]/mr-yum-attributes',
    roles: FEATURE_PERMISSIONS.meanduAttributes.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/mr-yum-attributes')(pathData),
  } as ManageRoute<VenueSlug>,
  orders: {
    label: 'Live Orders',
    href: () => '/venues/[venueId]/orders/[tab]',
    roles: FEATURE_PERMISSIONS.liveOrders.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/orders/:tab')(pathData),
  } as ManageRoute<
    VenueTabSlug<'board' | 'ready' | 'complete' | 'rejected' | 'custom'>
  >,
  waitTimes: {
    label: 'Wait Times',
    href: () => '/venues/[venueId]/wait-times',
    roles: FEATURE_PERMISSIONS.waitTimes.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/wait-times')(pathData),
  } as ManageRoute<VenueSlug>,
  orderHistory: {
    label: 'Orders & Refunds',
    href: () => '/venues/[venueId]/order-history',
    roles: validOrderHistoryRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/order-history')(pathData),
  } as ManageRoute<VenueSlug>,
  orderAvailability: {
    label: 'Turn On/Off',
    href: () => '/venues/[venueId]/order-availability',
    roles: FEATURE_PERMISSIONS.orderAvailability.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/order-availability')(pathData),
  } as ManageRoute<VenueSlug>,
  orderingTypeSettings: {
    label: 'Ordering Type Settings',
    href: () => '/venues/[venueId]/ordering-type-settings/[orderingTypeSlug]',
    roles: FEATURE_PERMISSIONS.orderingTypeSettings.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile(
        '/venues/:venueId/ordering-type-settings/:orderingTypeSlug',
      )(pathData),
  } as ManageRoute<VenueOrderingTypeSlug>,
  orderingWindows: {
    label: 'Ordering Windows',
    href: () => '/venues/[venueId]/ordering-windows',
    roles: FEATURE_PERMISSIONS.orderingWindows.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/ordering-windows')(pathData),
  } as ManageRoute<VenueSlug>,
  tipping: {
    label: 'Tipping',
    href: () => '/venues/[venueId]/tipping',
    roles: FEATURE_PERMISSIONS.tipping.writeRoles.map(mapStaffRoleToValidRole),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/tipping')(pathData),
  } as ManageRoute<VenueSlug>,
  smsNotifications: {
    label: 'SMS Notifications',
    href: () => '/venues/[venueId]/sms-notifications',
    roles: FEATURE_PERMISSIONS.notifications.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/sms-notifications')(pathData),
  } as ManageRoute<VenueSlug>,
  tabs: {
    label: 'Group Ordering',
    href: () => '/venues/[venueId]/group-ordering',
    roles: FEATURE_PERMISSIONS.groupOrdering.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/group-ordering')(pathData),
  } as ManageRoute<VenueSlug>,
  marketingOptIn: {
    label: 'Marketing Opt-In',
    href: () => '/venues/[venueId]/marketing-opt-in',
    roles: FEATURE_PERMISSIONS.marketingOptIn.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/marketing-opt-in')(pathData),
  } as ManageRoute<VenueSlug>,
  optedInCustomers: {
    label: 'Customer Database',
    href: () => '/venues/[venueId]/opted-in-customers',
    roles: FEATURE_PERMISSIONS.customerDatabase.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/opted-in-customers')(pathData),
  } as ManageRoute<VenueSlug>,
  facebookPixel: {
    label: 'Social Media Integrations',
    href: () => '/venues/[venueId]/facebook-pixel',
    roles: FEATURE_PERMISSIONS.socialMediaIntegrations.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/facebook-pixel')(pathData),
  } as ManageRoute<VenueSlug>,
  tableBooking: {
    label: 'Table Booking',
    href: () => '/venues/[venueId]/table-booking',
    roles: FEATURE_PERMISSIONS.tableBooking.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/table-booking')(pathData),
  } as ManageRoute<VenueSlug>,
  salesReport: {
    label: 'Sales Report',
    href: () => '/venues/[venueId]/sales-report',
    roles: validSalesReportRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/sales-report')(pathData),
  } as ManageRoute<VenueSlug>,
  itemsReport: {
    label: 'Item Summary',
    href: () => '/venues/[venueId]/items-report',
    roles: validSalesReportRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/items-report')(pathData),
  } as ManageRoute<VenueSlug>,
  tableSpendReport: {
    label: 'Table Spend Summary',
    href: () => '/venues/[venueId]/table-spend-report',
    roles: FEATURE_PERMISSIONS.tableSpendReport.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/table-spend-report')(pathData),
  } as ManageRoute<VenueSlug>,
  itemisedReport: {
    label: 'Itemised Report',
    href: () => '/venues/[venueId]/itemised-report',
    roles: validSalesReportRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/itemised-report')(pathData),
  } as ManageRoute<VenueSlug>,
  topCustomers: {
    label: 'Top Customers',
    href: () => '/venues/[venueId]/top-customers',
    roles: validSalesReportRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/top-customers')(pathData),
  } as ManageRoute<VenueSlug>,
  paymentSettings: {
    label: 'Payment Settings',
    href: () => '/venues/[venueId]/payment-settings',
    roles: FEATURE_PERMISSIONS.paymentSettings.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/payment-settings')(pathData),
  } as ManageRoute<VenueSlug>,
  venueStripeConnect: {
    label: 'Stripe Connect',
    href: () => '/venues/[venueId]/stripe-connect',
    roles: FEATURE_PERMISSIONS.stripeConnect.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/stripe-connect')(pathData),
  } as ManageRoute<VenueSlug>,
  bankDetails: {
    label: 'Bank Details',
    href: () => '/venues/[venueId]/bank-details',
    roles: FEATURE_PERMISSIONS.bankDetails.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/bank-details')(pathData),
  } as ManageRoute<VenueSlug>,
  payouts: {
    label: 'Payouts',
    href: () => '/venues/[venueId]/payouts',
    roles: FEATURE_PERMISSIONS.payouts.writeRoles.map(mapStaffRoleToValidRole),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/payouts')(pathData),
  } as ManageRoute<VenueSlug>,
  advancedAnalytics: {
    label: 'Advanced Analytics',
    href: () => '/venues/[venueId]/advanced-analytics',
    roles: FEATURE_PERMISSIONS.advancedAnalytics.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/advanced-analytics')(pathData),
  } as ManageRoute<VenueSlug>,
  venuePerformance: {
    label: 'Venue Performance',
    href: () => '/venues/[venueId]/venue-performance',
    roles: FEATURE_PERMISSIONS.venuePerformanceDashboard.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/venue-performance')(pathData),
  } as ManageRoute<VenueSlug>,
  printerSetup: {
    label: 'Printer Setup',
    href: () => '/venues/[venueId]/printer-setup',
    roles: FEATURE_PERMISSIONS.printerSetup.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/printer-setup')(pathData),
  } as ManageRoute<VenueSlug>,
  printerJobs: {
    label: 'Printer Jobs',
    href: () => '/venues/[venueId]/printer-jobs',
    roles: FEATURE_PERMISSIONS.printerJobs.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/printer-jobs')(pathData),
  } as ManageRoute<VenueSlug>,
  printerGroups: {
    label: 'Printer Groups',
    href: () => '/venues/[venueId]/printer-groups',
    roles: FEATURE_PERMISSIONS.printerGroupPage.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/printer-groups')(pathData),
  } as ManageRoute<VenueSlug>,
  batchingGroups: {
    label: 'Batching Groups',
    href: () => '/venues/[venueId]/batching-groups',
    roles: FEATURE_PERMISSIONS.batchingGroups.writeRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/batching-groups')(pathData),
  } as ManageRoute<VenueSlug>,
  onboardingVideos: {
    label: 'Training Videos',
    href: () => '/venues/[venueId]/onboarding-videos',
    roles: FEATURE_PERMISSIONS.helpCentre.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/onboarding-videos')(pathData),
    external:
      'https://help.meandu.com/hc/en-us/articles/6539898990863-Staff-training-resources',
  } as ManageRoute<VenueSlug>,
  helpCentre: {
    label: 'Help Centre',
    href: () => '/venues/[venueId]/help-centre',
    roles: FEATURE_PERMISSIONS.helpCentre.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/help-centre')(pathData),
    external: 'https://help.meandu.com/',
  } as ManageRoute<VenueSlug>,
  community: {
    label: 'Community',
    href: () => '/venues/[venueId]/community',
    roles: FEATURE_PERMISSIONS.helpCentre.readRoles.map(
      mapStaffRoleToValidRole,
    ),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/community')(pathData),
    external: 'https://help.meandu.com/hc/en-us/community/topics',
  } as ManageRoute<VenueSlug>,
  bulkEditItems: {
    label: 'Bulk Edit',
    href: () => '/venues/[venueId]/menu/bulk/items',
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/menu/bulk/items')(pathData),
    roles: validBulkEditMenuItemsRoles,
  } as ManageRoute<VenueSlug>,
  bulkEditImport: {
    label: 'Bulk Edit',
    href: () => '/venues/[venueId]/menu/bulk/import',
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/menu/bulk/import')(pathData),
    roles: validBulkEditMenuItemsRoles,
  } as ManageRoute<VenueSlug>,
  addMenu: {
    label: 'Upload Menu Items',
    href: () => '/venues/[venueId]/menu/upload',
    roles: validBulkEditMenuItemsRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/menu/upload')(pathData),
  } as ManageRoute<VenueSlug>,
  order: {
    label: 'Order',
    href: () => '/venues/[venueId]/order/[orderId]',
    roles: validOrderHistoryRoles,
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/order/:orderId')(pathData),
  } as ManageRoute<VenueOrderSlug>,
  everything: {
    label: 'Everything',
    href: () => '/everything/[view]',
    roles: ['admin'],
    toPath: (pathData) => pathToRegexp.compile('/everything/:view')(pathData),
  } as ManageRoute<EverythingSlug>,
  loke: {
    label: 'loke',
    href: () => '/loke',
    roles: ['admin'],
  } as ManageRoute,
  users: {
    label: 'Users',
    href: () => '/users',
    roles: FEATURE_PERMISSIONS.users.writeRoles.map(mapStaffRoleToValidRole),
  } as ManageRoute,
  staff: {
    label: 'Staff',
    href: () => '/staff',
    roles: FEATURE_PERMISSIONS.staff.writeRoles.map(mapStaffRoleToValidRole),
  } as ManageRoute,
  login: {
    label: 'Login',
    href: () => '/login',
  } as ManageRoute,
  forgotPassword: {
    label: 'Forgot Password',
    href: () => '/forgot-password',
  } as ManageRoute,
  logout: {
    label: 'Logout',
    href: () => '/logout',
  } as ManageRoute,
  qrRedirects: {
    label: 'QR Codes',
    href: () => '/qr-redirects',
    roles: FEATURE_PERMISSIONS.qrRedirectsPage.readRoles.map(
      mapStaffRoleToValidRole,
    ),
  } as ManageRoute,
  sorry: {
    label: 'Sorry',
    href: () => '/sorry',
  } as ManageRoute,
  health: {
    label: 'Health',
    href: () => '/health',
  } as ManageRoute,
  accessDenied: {
    label: 'Access Denied',
    href: () => '/access-denied',
  } as ManageRoute,
  noVenueFound: {
    label: 'No Venue Found',
    href: () => '/no-venue-found',
  } as ManageRoute,
  transferFees: {
    label: 'Transfer Fees',
    href: () => '/transfer-fees',
    roles: [],
  } as ManageRoute,
  billing: {
    label: 'Billing',
    href: () => '/venues/[venueId]/billing',
    roles: FEATURE_PERMISSIONS.billing.writeRoles.map(mapStaffRoleToValidRole),
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/billing')(pathData),
  } as ManageRoute<VenueSlug>,
  billingInvoice: {
    label: 'Billing Invoice',
    roles: FEATURE_PERMISSIONS.billing.writeRoles.map(mapStaffRoleToValidRole),
    href: () => '/venues/[venueId]/billing/[invoiceId]',
    toPath: (pathData) =>
      pathToRegexp.compile('/venues/:venueId/billing/:invoiceId')(pathData),
  } as ManageRoute<VenueBillingInvoiceSlug>,
  sisenseSsoHandler: {
    label: 'Sisense SSO Handler',
    href: () => '/sisense/sso-handler',
  } as ManageRoute,
  sisenseSsoLogout: {
    label: 'Sisense SSO Logout',
    href: () => '/sisense/sso-logout',
  } as ManageRoute,
}

export type RouteName = keyof typeof routes
