import { urqlFetchWithLogging } from '@mr-yum/frontend-core/dist/support/urql'
import { devtoolsExchange } from '@urql/devtools'
import { authExchange } from '@urql/exchange-auth'
import { Cache, cacheExchange, DataFields } from '@urql/exchange-graphcache'
import { relayPagination } from '@urql/exchange-graphcache/extras'
import { retryExchange } from '@urql/exchange-retry'
import { apiUrlForDomain } from 'hooks/useApiUrl'
import { useAuth } from 'hooks/useAuth'
import { reportError, reportErrorContext } from 'lib/bugsnag'
import React, { ReactNode, useCallback, useMemo } from 'react'
import {
  Client,
  createClient,
  fetchExchange,
  makeOperation,
  mapExchange,
  Provider,
} from 'urql'
import { extractFiles } from 'utils/extract-files'

import { appVersion, config } from '../lib/./config'
import {
  DeleteModifierGroupResponse,
  UpdateVenueInfoV4Mutation,
  VenueOrderingAvailabilityInput,
} from '../lib/./gql'
import { getTokenExpiry } from './AuthController'

// Additional logging for development environment
const urqlFetch =
  config.environment === 'development'
    ? (urqlFetchWithLogging as typeof fetch)
    : undefined

const graphqlUrl = (url: string) => {
  return `${url}/graphql`
}

const invalidate = (fieldNames: string | string[], cache: Cache) => {
  const cartQueries = cache
    .inspectFields('Query')
    .filter((x) => fieldNames.includes(x.fieldName))

  cartQueries.forEach(({ fieldName, arguments: variables }) => {
    cache.invalidate('Query', fieldName, variables || undefined)
  })
}

const isDeleteModifierGroupAndListRelationsResponse = (
  response: DataFields,
): response is DataFields & {
  deleteModifierGroupAndListRelations: DeleteModifierGroupResponse
} => response.deleteModifierGroupAndListRelations !== undefined

export const UrqlProvider = ({ children }: { children: ReactNode }) => {
  const { getToken, logout, refreshAuth } = useAuth()

  const getTokenWithErrorHandling = useCallback(async () => {
    try {
      const token = await getToken()
      return token
    } catch (err) {
      reportError(err)
      return null
    }
  }, [getToken])

  const client = useMemo(() => {
    return getClient(getTokenWithErrorHandling, logout, refreshAuth)
  }, [getTokenWithErrorHandling, logout, refreshAuth])

  return <Provider value={client}>{children}</Provider>
}

export const getClient = (
  getToken: () => Promise<string | null>,
  logout: () => void,
  refreshAuth: () => Promise<string | null>,
): Client => {
  const url = apiUrlForDomain(window.location.host.toString())

  return createClient({
    url: graphqlUrl(url),
    requestPolicy: 'cache-and-network',
    fetch: urqlFetch,
    suspense: false,
    exchanges: [
      devtoolsExchange,
      cacheExchange({
        // mutations which affect validity of cached objects, but don't return new objects in their response
        // https://formidable.com/open-source/urql/docs/graphcache/custom-updates/
        updates: {
          Mutation: {
            // mutation returns boolean, but affects other menuSections
            addMenuItemToSection: (_result, args, cache) => {
              cache.invalidate({
                __typename: 'MenuSection',
                id: String(args.menuSectionId),
              })
            },
            // mutation returns boolean, but affects other menuSections
            removeMenuItemFromSection: (_result, args, cache) => {
              cache.invalidate({
                __typename: 'MenuSection',
                id: String(args.menuSectionId),
              })
            },
            updateVenueInfoV4: (
              result: UpdateVenueInfoV4Mutation,
              _args,
              cache,
            ) => {
              const id = result.updateVenueInfoV4.id
              cache.invalidate({
                __typename: 'Venue',
                id: String(id),
              })
            },
            // mutation returns boolean, but affects other menuSections
            moveMenuItemToSectionV2: (_result, args, cache) => {
              cache.invalidate({
                __typename: 'MenuSection',
                id: String(args.fromSectionId),
              })
              cache.invalidate({
                __typename: 'MenuSection',
                id: String(args.toSectionId),
              })
            },
            deleteTimeBlock: (_result, args, cache) => {
              cache.invalidate({
                __typename: 'TimeBlocks',
                id: String(args.id),
              })
            },
            deleteCrmIntegration: (result, args, cache) => {
              cache.invalidate({
                __typename: 'CrmIntegrationPayload',
                id: String(args.id),
                organizationId: String(args.organizationId),
                type: String(args.type),
              })
            },
            updateTimeBlock: (_result, args, cache) => {
              cache.invalidate('Query', 'bannerInfo', { venueId: args.venueId })
              cache.invalidate('Query', 'bannerNextOpenMessage', {
                venueId: args.venueId,
              })
            },
            updateOrderingAvailability: (_result, args, cache) => {
              cache.invalidate('Query', 'bannerInfo', {
                venueId: (args.data as VenueOrderingAvailabilityInput).venueId,
              })
            },
            turnOnAllOrderingTypes: (_result, args, cache) => {
              cache.invalidate('Query', 'bannerInfo', {
                venueId: args.data,
              })
            },
            createModifierGroup: (_result, _args, cache) => {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'searchModifierGroups')
                .forEach((field) => cache.invalidate('Query', field.fieldKey))
            },
            deleteModifierGroupAndListRelations: (result, args, cache) => {
              cache.invalidate('searchModifierGroups')

              cache.invalidate({
                __typename: 'MenuItemModifierGroup',
                id: String(args.modifierGroupId),
              })

              if (isDeleteModifierGroupAndListRelationsResponse(result)) {
                result.deleteModifierGroupAndListRelations?.linkedMenuItemIds.forEach(
                  (id: string) => {
                    cache.invalidate({
                      __typename: 'MenuItem',
                      id,
                    })
                  },
                )
              }
            },

            deleteModifierGroup: (_result, args, cache) => {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'searchModifierGroups')
                .forEach((field) => cache.invalidate('Query', field.fieldKey))

              cache.invalidate({
                __typename: 'MenuItemModifierGroup',
                id: String(args.modifierGroupId),
              })
            },
            upsertLinkedMenuItemsToPriceAdjustment: (_result, _args, cache) => {
              invalidate('priceAdjustment', cache)
            },
            deletePriceAdjustment: (_result, _args, cache) => {
              invalidate('priceAdjustments', cache)
            },
            unlinkMenuItemToPriceAdjustment: (_result, _args, cache) => {
              invalidate('priceAdjustment', cache)
            },
            removeModifierGroupFromMenuItem: (_result, args, cache) => {
              const { removeModifierGroupFromMenuItem } = _result ?? {}
              if (typeof removeModifierGroupFromMenuItem === 'boolean') {
                // backwards compat
                cache.invalidate({
                  __typename: 'MenuItem',
                  id: args.menuItemId as string,
                })
              } else if (typeof removeModifierGroupFromMenuItem === 'string') {
                cache.invalidate({
                  __typename: 'MenuItemToModifierGroup',
                  id: removeModifierGroupFromMenuItem,
                })
              }
            },
            createModifierItem: (_result, _args, cache) => {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'venueModifiers')
                .forEach((field) => cache.invalidate('Query', field.fieldKey))
            },
            createModifierGroupModifier: (_result, args, cache) => {
              cache.invalidate({
                __typename: 'MenuItemModifierGroup',
                id: String(args.modifierGroupId),
              })
            },
            modifierItemRemove: (_result, _args, cache) => {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'venueModifiers')
                .forEach((field) => cache.invalidate('Query', field.fieldKey))
            },
            linkMenuItemsToModifierGroup: (_result, args, cache) => {
              cache.invalidate({
                __typename: 'MenuItemModifierGroup',
                id: String(args.modifierGroupId),
              })
            },
            updateModifierGroupModifiers: (_result, args, cache) => {
              cache.invalidate({
                __typename: 'ModifierGroup',
                id: String(args.modifierGroupId),
              })
            },
            unlinkSingleModifierFromModifierGroup: (_result, args, cache) => {
              cache.invalidate({
                __typename: 'ModifierGroup',
                id: String(args.modifierGroupId),
              })
            },
            repositionModifierToModifierGroup: (_result, args, cache) => {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'searchModifierGroups' &&
                    field.arguments?.query === args.modifierGroupId,
                )
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey)
                })
            },
          },
        },

        // __typenames which don't always have an id or _id (skip normalizing them in the cache)
        // https://formidable.com/open-source/urql/docs/graphcache/normalized-caching/#key-generation
        keys: {
          AirwallexBeneficiary: () => null,
          AirwallexBeneficiaryFieldSchema: () => null,
          AirwallexBeneficiaryFormFieldRuleSchema: () => null,
          AirwallexBeneficiaryFormFieldSchema: () => null,
          AirwallexBeneficiaryFieldOptionSchema: () => null,
          AirwallexBeneficiaryFormSchema: () => null,
          BannerOrderingSettings: () => null,
          PriceData: () => null,
          CdnImage: () => null,
          PosIntegration: () => null,
          TimeSlot: () => null,
          TimeFrame: () => null,
          GlobalWeekOnWeekRow: () => null,
          AverageTransactionPerCustomer: () => null,
          RevenueReport: () => null,
          CustomerReport: () => null,
          SupportChatUserDto: () => null,
          MarketingOptInParts: () => null,
          MenuConfig: () => null,
          MenuConfigEntity: () => null,
          MenuConfigField: () => null,
          PaginatedVenueCollectionResponse: () => null,
          CsrfTokenDto: () => null,
          SearchModifierGroupsResponse: () => null,
          StripeAccountCompany: () => null,
          StripeAccountAddress: () => null,
          StripeAccountRequirements: () => null,
          StripeAccountIndividual: () => null,
          StripeAccountRelationship: () => null,
          StripeAccountDOB: () => null,
          StripeAccountVerification: () => null,
          StripeAccountVerificationDocument: () => null,
          StripeAccountExternalAccounts: () => null,
          StripeAccountExternalAccount: () => null,
          StripeAccountBusinessProfile: () => null,
          OrganizationLoyaltyUser: () => null,
          OrganizationWithVenuesSearchResult: () => null,
          ZendeskAuth: () => null,
        },

        resolvers: {
          Query: {
            staffByOrganization: relayPagination(),
          },
        },
      }),
      mapExchange({
        onOperation(operation) {
          const { fetchOptions } = operation.context
          const fetchOptionsPayload =
            typeof fetchOptions === 'function'
              ? fetchOptions()
              : fetchOptions ?? {}

          const isFileUpload = extractFiles(operation.variables).size > 0
          const headers: HeadersInit = {
            ...fetchOptionsPayload.headers,
            'apollographql-client-name': 'admin',
            'apollographql-client-version': appVersion,
            'apollo-require-preflight': 'true',
            // // urql doesnt change this header if it already exists so we have to be careful attaching it
            ...(!isFileUpload ? { 'content-type': 'application/json' } : {}),
          }

          const mappedFetchOptions: RequestInit = {
            ...fetchOptionsPayload,
            credentials: 'include',
            headers,
          }
          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: mappedFetchOptions,
          })
        },
        onError(error, operation) {
          // https://www.websitepulse.com/kb/5xx_http_status_codes
          const SERVER_ERRORS = [500, 502, 503, 504]
          // could be multiple field resolvers in the query
          const queryNames = operation.query.definitions
            .map((def: any) => def.name?.value)
            .join('/')

          // dont send Auth errors to bugsnag - this is an expected error
          if (
            error.graphQLErrors?.some((e) =>
              e.name.includes('AuthenticationError'),
            )
          ) {
            return
          }

          if (
            error.response &&
            error.response.status &&
            typeof error.response?.status === 'number'
          ) {
            if (SERVER_ERRORS.includes(error.response.status)) {
              reportErrorContext(
                new Error(
                  `${error.message}(HTTP ${error.response.status}) for ${queryNames}`,
                ),
                // context
                'Generic 5xx API Error',
              )
            }
          }
        },
      }),
      authExchange(async (utils) => {
        // called on initial launch,
        let token = await getToken()
        let expires = getTokenExpiry(token)
        const clearSession = () => {
          token = null
          expires = new Date(0)
          logout()
        }

        return {
          addAuthToOperation(operation) {
            // stop-gap while we support old auth. In future all endpoints should have auth
            if (token) {
              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${token}`,
              })
            }
            return operation
          },
          willAuthError() {
            // e.g. check for expiration, existence of auth etc
            return expires <= new Date()
          },
          didAuthError(error, _operation) {
            // check if the error was an auth error
            // we should get a better status?
            return error.graphQLErrors.some((e) =>
              e.name.includes('AuthenticationError'),
            )
          },
          async refreshAuth() {
            try {
              token = await refreshAuth()

              // fail to refresh token
              if (!token) {
                return
              }

              expires = getTokenExpiry(token)

              if (expires <= new Date()) {
                clearSession()
              }
            } catch {
              clearSession()
            }
          },
        }
      }),
      // @ts-expect-error complains about types but seems to work fine. Upgrading urql should fix this https://github.com/urql-graphql/urql/issues/1017
      retryExchange({
        initialDelayMs: 1000,
        maxDelayMs: 15000,
        maxNumberAttempts: 5,
      }),
      fetchExchange,
    ],
    preferGetMethod: true,
  })
}
