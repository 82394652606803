import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import {
  BaseProvider,
  Block,
  Button,
  ErrorNotification,
  HeadingMedium,
  Image,
  mrYumTheme,
  Notification,
  styletron,
  StyletronProvider,
} from '@mr-yum/yum-ui'
import { routes } from 'lib/routes'
import React, { ReactNode } from 'react'

interface NotFoundProps {
  title?: ReactNode
  message?: ReactNode
  error?: Error | null
  clearError?: () => void
}

export const NotFound: React.FC<NotFoundProps> = ({
  title,
  message,
  error,
  clearError,
}) => (
  <StyletronProvider value={styletron}>
    <BaseProvider theme={mrYumTheme}>
      <style
        dangerouslySetInnerHTML={{
          __html: mrYumTheme.globalCss,
        }}
      />
      <Block
        display="flex"
        padding="scale600"
        width="100vw"
        height="100vh"
        color="accentBrandingText"
        backgroundColor="#FFFAF3"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        $style={{ textAlign: 'center' }}
      >
        <Image
          src="/static/mryum_assets/toast.png"
          alt="burnt toast"
          width={100}
        />

        <HeadingMedium marginBottom="scale900">
          {title || `Bummer, we got nothin'. Please check the URL for typos.`}
        </HeadingMedium>

        <Block display="flex" flexDirection="row">
          <RouteLink route={routes.home}>
            <Button>Go back</Button>
          </RouteLink>

          {clearError && (
            <Block marginLeft="scale400">
              <Button onClick={clearError}>Try again</Button>
            </Block>
          )}
        </Block>

        {message && (
          <Block marginTop="scale1400">
            <Notification
              closeable
              overrides={{
                Body: {
                  style: {
                    paddingTop: '0.7em',
                    paddingLeft: '0.7em',
                    paddingRight: '0.7em',
                    paddingBottom: '0.7em',
                    fontSize: '0.8em',
                  },
                },
              }}
            >
              {message}
            </Notification>
          </Block>
        )}

        {error && (
          <Block marginTop="scale1400">
            <ErrorNotification
              closeable
              overrides={{
                Body: {
                  style: {
                    paddingTop: '0.7em',
                    paddingLeft: '0.7em',
                    paddingRight: '0.7em',
                    paddingBottom: '0.7em',
                    fontSize: '0.8em',
                  },
                },
              }}
              kind="info"
            >
              {error}
            </ErrorNotification>
          </Block>
        )}
      </Block>
    </BaseProvider>
  </StyletronProvider>
)
