import '../styles/global.css'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import 'react-datepicker/dist/react-datepicker.css'
import '../components/Common/datepicker/datepicker.css'

import { AppState, Auth0Provider } from '@auth0/auth0-react'
import {
  BaseProvider,
  mrYumTheme,
  styletron,
  StyletronProvider,
  ToasterContainer,
  ToastPlacement,
} from '@mr-yum/yum-ui'
import { AmplitudeProvider } from 'components/Tracking/AmplitudeTracking'
import { GoogleTagManager } from 'components/Tracking/GoogleTagManagerTracking'
import {
  AUTH0_TOKEN_SKEW_IN_SECONDS,
  AuthController,
} from 'contexts/AuthController'
import { UnleashProvider, UnleashUserUpdater } from 'contexts/withUnleash'
import { UrqlProvider } from 'contexts/withUrql'
import { useNotOutdatedBrowser } from 'hooks/useNotOutdatedBrowser'
import { Bugsnag } from 'lib/bugsnag'
import { ChatSupport } from 'lib/ChatSupport'
import { config } from 'lib/config'
import { withRegion } from 'lib/withRegion'
import NextApp, { AppContext, AppInitialProps, AppProps } from 'next/app'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import React, { useMemo } from 'react'
import { Toaster } from 'react-hot-toast'
import { getMetaTitle } from 'utils/getMetaTitle'
import { isServer } from 'utils/next'

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)!

interface Props {
  venueId: string
}

NProgress.configure({
  minimum: 0.2,
  trickleSpeed: 100,
  easing: 'ease',
  speed: 500,
  showSpinner: false,
})

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function App({ Component, pageProps, router }: AppProps & Props) {
  useNotOutdatedBrowser(router.asPath)
  const title = getMetaTitle()
  const { replace, query } = useRouter()
  const onRedirectCallback = (appState?: AppState) => {
    if (appState && appState.returnTo) {
      void replace(appState.returnTo)
    }
  }

  const organizationId = Array.isArray(query.organizationId)
    ? query.organizationId[0]
    : query.organizationId

  const venueId = Array.isArray(query.venueId)
    ? query.venueId[0]
    : query.venueId

  const preventZoom = useMemo(() => {
    if (isServer) return false

    try {
      // user agent detection is a necessary evil so we can prevent iOS from zooming in on input focus when font size < 16px
      // need to add viewport settings conditionally; don't want to disable user scaling on Android (iOS10+ ignores)
      return navigator.userAgent.match(/iPhone|iPod/i)
    } catch (e) {
      return false
    }
  }, [])

  return (
    <ErrorBoundary>
      <Head>
        <title>{title}</title>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <meta name="msapplication-TileColor" content="#DBFC45" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${config.googlePlacesApiKey}&libraries=places`}
      />

      <StyletronProvider value={styletron}>
        <BaseProvider theme={mrYumTheme} zIndex={12}>
          <style
            dangerouslySetInnerHTML={{
              __html: mrYumTheme.globalCss,
            }}
          />
          <ToasterContainer
            placement={ToastPlacement.bottom}
            autoHideDuration={15000}
            overrides={{
              Root: {
                style: () => {
                  return {
                    zIndex: 13,
                  }
                },
              },
            }}
          />
          <Toaster />

          <Auth0Provider
            useRefreshTokens={true}
            /*
              https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/15
              https://github.com/auth0/auth0-spa-js/pull/1079/files
            */
            useRefreshTokensFallback={true}
            domain={config.auth0Domain}
            clientId={config.auth0ClientId}
            onRedirectCallback={onRedirectCallback}
            leeway={AUTH0_TOKEN_SKEW_IN_SECONDS}
            authorizationParams={{
              redirect_uri:
                typeof window !== 'undefined'
                  ? window.location.origin
                  : config.auth0RedirectUri,
              audience: config.auth0Audience,
              scope: 'openid profile email offline_access',
            }}
          >
            <AuthController>
              <UnleashProvider>
                <UrqlProvider>
                  <GoogleTagManager />
                  <AmplitudeProvider>
                    <UnleashUserUpdater
                      organizationId={organizationId}
                      venueId={venueId}
                    >
                      <Head>
                        {/* Adjust viewport here, as it's not allowed in _document.page @see https://nextjs.org/docs/messages/no-document-viewport-meta */}
                        <meta
                          key="viewport"
                          name="viewport"
                          content={`width=device-width, initial-scale=1 ${preventZoom ? 'shrink-to-fit=no, maximum-scale=1' : ''}`}
                        />
                      </Head>
                      <Component {...pageProps} />
                      <ChatSupport />
                    </UnleashUserUpdater>
                  </AmplitudeProvider>
                </UrqlProvider>
              </UnleashProvider>
            </AuthController>
          </Auth0Provider>
        </BaseProvider>
      </StyletronProvider>
    </ErrorBoundary>
  )
}

export const getInitialProps = (App.getInitialProps = async (
  context: AppContext,
): Promise<AppInitialProps & Props> => {
  const appProps = await NextApp.getInitialProps(context)

  const queryVenueId = context.ctx.query.venueId || ''
  // bring this into context on specific pages instead
  const venueId = Array.isArray(queryVenueId) ? queryVenueId[0] : queryVenueId

  return { ...appProps, venueId }
})

// Assign to intermidiate var for type checking result of combining all the layers.
// The approach is that props and context are mostly same as we aren't really
// doing pre-rendering anymore. We only need the getInitalProps for auth
// redirecting and defaulting of the region and auth cookies
const composedApp = withRegion(App)

export default composedApp
